<template>
  <div>
    <section-hero class="pt-14" />
    <section-benefits
      id="benefits"
      class="mt-8 sm:mt-16"
    />
    <section-features class="mt-32 sm:mt-56" />
    <section-steps class="mt-32 sm:mt-56" />
    <section-cases class="mt-32 sm:mt-56" />
    <section-pricing class="mt-32 sm:mt-56" />
  </div>
</template>

<script setup lang="ts">
useHead({
  title: `${appName} - ${appTaglineShort}`,
  meta: [{
    name: 'description',
    content: appDescriptionShort
  }],
})
</script>
