<template>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <section-title
      v-bind="titleProps"
      class="mx-auto max-w-2xl lg:text-center"
    />

    <div class="mx-auto mt-16 max-w-7xl sm:mt-20 md:mt-24">
      <dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-base/7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
        <div
          v-for="benefit in benefits"
          :key="benefit.title"
          class="relative pl-9"
        >
          <dt class="inline font-semibold text-gray-900">
            <icon
              :name="benefit.icon"
              aria-hidden="true"
              class="absolute left-1 top-1 size-5 text-red-600"
            />

            {{ benefit.title }}
          </dt>

          {{ ' ' }}

          <dd class="inline">
            {{ benefit.description }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionTitleProps } from 'types/section'

const titleProps: SectionTitleProps = {
  kicker: 'Avantages',
  title: 'Attirez l\'attention des clients',
  description: `${appName} est idéal pour présenter vos offres et nouveautés à vos clients. Le défilement du diaporama sur vos écrans attirent les regards des passants et des clients. C'est un atout pour vous démarquer de la concurrence.`
}

const benefits = [
  {
    icon: 'ph:chats-duotone',
    title: 'Communiquez efficacement',
    description: 'en suscitant l\'intérêt de vos clients avec des photos, vidéos et animations. Animez les infos que vous souhaitez mettre en avant.',
  },
  {
    icon: 'ph:chart-line-up-duotone',
    title: 'Augmentez vos ventes',
    description: 'en affichant de manière attrayante vos promotions et vos offres combinées. Encouragez vos clients à acheter une offre exclusive.',
  },
  {
    icon: 'ph:calendar-check-duotone',
    title: 'Gagnez du temps',
    description: 'en modifiant les infos à un endroit et depuis votre ordinateur. Planifiez en avance leur affichage à un horaire définis ou pour un événement spécifique.',
  },
  {
    icon: 'ph:piggy-bank-duotone',
    title: 'Économisez de l\'argent',
    description: `en utilisant les écrans que vous possédez déjà. ${appName} fonctionne sur tous les écrans : Smart TV, télévisions, moniteurs et tablettes.`,
  },
  {
    icon: 'ph:smiley-duotone',
    title: 'Améliorez l\'expérience de vos clients',
    description: 'en affichant des infos utiles. Par exemple : vos horaires, votre programme de fidelité, votre équipe, la météo locale, etc.',
  },
  {
    icon: 'ph:thumbs-up-duotone',
    title: 'Renforcez votre image de marque',
    description: 'en ajoutant votre logo, vos visuels et vos couleurs. Mettez en avant les témoignages et les avis positifs de vos clients.',
  }
]
</script>
