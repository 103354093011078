<template>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <section-title
      v-bind="titleProps"
      class="mx-auto max-w-2xl lg:text-center"
    />

    <ul
      role="list"
      class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:mt-20 sm:grid-cols-2 lg:mt-24 lg:mx-0 lg:max-w-none lg:grid-cols-3"
    >
      <li
        v-for="item in cases"
        :key="item.title"
      >
        <div class="bg-gradient-to-t from-red-100/10 to-red-100/90 ring-1 ring-inset ring-gray-900/10 rounded-3xl">
          <nuxt-img
            :alt="item.title"
            loading="lazy"
            :src="item.imageUrl"
            sizes="xs:320px sm:500px"
            class="aspect-[3/2] w-full rounded-2xl object-cover"
          />
        </div>

        <h3 class="mt-6 text-lg/8 font-semibold tracking-tight text-gray-900">
          {{ item.title }}
        </h3>

        <p class="text-base/7 text-gray-600">
          {{ item.description }}
        </p>
      </li>
    </ul>

    <div class="mt-16 flex justify-center">
      <nuxt-link
        to="/contact"
        class="group relative text-center rounded-3xl sm:rounded-full px-4 py-1.5 text-sm/6 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20"
      >
        <span class="text-gray-600">
          Un autre secteur d'activité ou des questions ?
        </span>

        <span class="font-semibold text-gray-900 group-hover:text-red-600 block sm:inline">
          Contactez-nous
        </span>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionTitleProps } from 'types/section'

const titleProps: SectionTitleProps = {
  kicker: 'Usages',
  title: 'Communiquez avec vos écrans',
  description: `${appName} est le logiciel de diaporama pour vos écrans. Vous construisez un diaporama adapté à votre secteur d'activité. Vous personnalisez ainsi votre communication spécialement pour votre clientèle.`
}

const cases = [
  {
    title: 'Restaurants, cafés et bars',
    description: 'Affichez pour vos clients le menu, les plats du jour, les offres spéciales, les événements à venir, les avis positifs, la suggestion du chef et bien plus.',
    imageUrl: '/images/restaurant.webp'
  },
  {
    title: 'Magasins et marchés',
    description: 'Affichez pour vos clients vos horaires, votre produit phare, les nouveautés, les produits soldés, les offres spéciales, les avis positifs et bien plus.',
    imageUrl: '/images/store.webp'
  },
  {
    title: 'Salles de sport et fitness',
    description: 'Affichez pour vos clients vos produits en vente, des conseils, les prochains cours collectifs, des citations et photos motivantes, et bien plus.',
    imageUrl: '/images/fitness.webp'
  },
  {
    title: 'Hôtels et auberges',
    description: 'Affichez pour vos clients l\'horaire du petit-déjeuner, vos services, vos suggestions locales, des infos pratiques et bien plus.',
    imageUrl: '/images/hotel.webp'
  },
  {
    title: 'Salles d\'attente',
    description: 'Affichez pour vos clients vos horaires, vos prestations, la météo, des infos pratiques, les avis positifs, présentez vos employés et bien plus.',
    imageUrl: '/images/waiting-room.webp'
  },
  {
    title: 'Musées',
    description: 'Affichez pour vos visiteurs la prochaine exposition, des infos pratiques, un plan du musée, un lien vers un jeu concours, et bien plus.',
    imageUrl: '/images/museum.webp'
  }
]
</script>
